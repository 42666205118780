import { SearchSongFilter } from '@/models/SearchSongFilter';
import { SongResult } from '@/models/SongResult';
import { ApplyFilters } from '@/models/ApplyFilters';
import { SearchSongData } from '@/models/SearchSongData';
import router from '@/router';
import { Component, Vue } from 'vue-property-decorator'
import { BusyService } from './BusyService';
import { ApiService } from './ApiService';
import { TerritoryService } from './TerritoryService';

@Component
class CatalogSongsServiceClass extends Vue {
  catalogId = '';
  numResults = 0;
  catalogLabel = '';
  searchResults: SongResult[] | null = null;

  query = {
    page: 1,
    sortBy: '',
    numPerPage: 10,
  }

  reset() {
    this.searchResults = null;
  }

  setQueryParams(){
    const data: {[key: string]: string[] | string} = {}

    if (this.query.page > 1){
      data.page = this.query.page.toString();
    }

    if (this.query.numPerPage != 10){
      data.numperpage = this.query.numPerPage.toString();
    }

    if (this.query.sortBy){
      data.sortBy = this.query.sortBy
    }

    router.push({ path: `/intellectualproperty/catalogs/${this.catalogId}`, query: data}).catch((err)=>{});
  }

  getQueryParams(){

    if (router.currentRoute.query.page) {
      this.query.page = parseInt(router.currentRoute.query.page as string)
    }else{
      this.query.page = 1;
    }

    if (router.currentRoute.query.numperpage) {
      this.query.numPerPage = parseInt(router.currentRoute.query.numperpage as string)
    }else{
      this.query.numPerPage = 10;
    }

    if (router.currentRoute.query.sortBy && ['TitleAsc', 'TitleDesc', 'LyricsAsc', 'LyricsDesc', 'SongIdAsc', 'SongIdDesc'].includes(router.currentRoute.query.sortBy as string)) {
      this.query.sortBy = router.currentRoute.query.sortBy as string;
    }else{
      this.query.sortBy = '';
    }
  }

  setSortBy(sortBy: string) {
    this.query.sortBy = sortBy;
    this.query.page = 1;
    this.setQueryParams();
  }

  onSetTerritory(){
    this.query.page = 1;
    this.setQueryParams();
    this.performSearch();
  }

  setNumPerPage(numPerPage: number){
    this.query.numPerPage = numPerPage;
    this.query.page = 1;
    this.setQueryParams();
  }

  setPage(page: number) {
    this.query.page = page;
    this.setQueryParams();
  }

  async performSearch() {
    BusyService.showBusy();
    // sample api call when endpoint is ready
    const response = await ApiService.post('/api/SearchSongs', {
      territoryId: parseInt(TerritoryService.territoryId),
      filters: JSON.stringify({
        adminIds: [],
        catalogIds: [this.catalogId],
        contributorIds: []
      }),
      search: '',
      page: this.query.page || 1,
      pageSize: this.query.numPerPage || 10,
      sortBy: this.query.sortBy || '', // options: TitleAsc, TitleDesc, LyricsAsc, LyricsDesc, SongIdAsc, SongIdDesc
    }) as SearchSongData;

    this.catalogLabel = response.currentFilters[0].label;
    this.searchResults = response.searchResults as SongResult[];
    this.numResults = response.numResults as number;
    BusyService.hideBusy();
  }

  lyricsCached: {[songId: string]: string[]} = {};
  async getLyrics(songId: string){
    if (!this.lyricsCached[songId]){
      const lyrics = await ApiService.post<string[]>('/api/GetSongLyricsPreview', { songId});
      this.lyricsCached[songId] = lyrics;
    }
    return this.lyricsCached[songId];
  }

  get downloadAllUrl() {
    const offset = new Date().getTimezoneOffset();
    return `/api/DownloadCatalog?CatalogId=${this.catalogId}&CatalogName=${encodeURIComponent(this.catalogLabel)}&Offset=${offset}&TerritoryId=${TerritoryService.territoryId}`
  }
}

export const CatalogSongsService = new CatalogSongsServiceClass()
